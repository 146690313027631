import apolloClient from "@packages/services/src/apolloClient"
import {
  LeadApps,
  SaveLeadDocument
} from "@packages/services/src/apolloClient/gql/graphql"

import LeadDataMapper, {
  DomainLeadData,
  type PersistenceLeadData
} from "../services/mappers/LeadDataMapper"
import { getUtms } from "../utils/getUtms"

export function getLeadData(){
  const quizStoragedLeadData = localStorage.getItem('zelasQuiz') ?? '{}'
  const leadData: PersistenceLeadData = JSON.parse(quizStoragedLeadData)

  return LeadDataMapper.toDomain(leadData)
}

export async function saveLeadData(leadData: DomainLeadData){
  const leadId = localStorage?.getItem?.('escaleLead') || ''
  const leadAddressStoraged = localStorage.getItem('@zelasQuiz/userAddress') ?? '{}'
  const leadAddress = JSON.parse(leadAddressStoraged)

  const {
    cnpj,
    cpf,
    email,
    birth_date,
    ...leadDataRest
  } = LeadDataMapper.toPersistence(leadData)

  const { data } = await apolloClient.mutate({
    mutation: SaveLeadDocument,
    variables: {
      leadData: {
        ...leadDataRest,
        ...cpf && {
          cpf
        },
        ...cnpj && {
          cnpj
        },
        ...email && {
          email
        },
        ...birth_date && {
          birth_date
        },
        leadId: leadId ?? '',
        website: 'saude.zelas.com.br/quiz',
        fonte_zd: 'organic_form_quiz',
        possui_cnpj: !!leadData.cnpj,
        city: leadAddress.city ?? '',
        state: leadAddress.state ?? '',
        ...getUtms()
      },
      from: LeadApps.Quiz
    }
  })

  const leadIdFromBff = data?.saveLead?.leadId

  const currentLeadId = leadIdFromBff || leadId || ''

  localStorage?.setItem('escaleLead', currentLeadId)
}
